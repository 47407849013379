<div id="techDashboard" class="container-fluid" *ngrxLet="location$ as location">
  <spacer>{{ 'techdash.infoArea.monitorAndValidateNetwork' | translate }}</spacer>

  <div class="title network">{{ 'techdash.infoArea.networkInformation' | translate }}</div>

  <div class="grid">
    <div class="column">
      <div class="row" *ngIf="!network.wpa2ssid && !network.wpa3ssid">
        <div class="key">{{ 'techdash.infoArea.ssid' | translate }}</div>
        <div class="controls solo">
          <div class="pill" [class.disabled]="!permissions?.uiFeatures.editSSID" (click)="openNetworkSettings()">
            <div class="label pre">
              <div>{{ network.ssid || ('health.networkInformation.na' | translate) }}</div>
              <icon name="icon-edit" *ngIf="permissions?.uiFeatures.editSSID"></icon>
            </div>
          </div>
          <icon
            class="recommendation"
            name="icon-alert-warning"
            tooltip="{{ 'health.networkInformation.badgeWarning' | translate }}"
            *ngIf="network.ssid && (WPA3Recommendation$ | async)"
          ></icon>
        </div>
      </div>
      <div class="row" [class.disabled]="!network.wpa2enabled" *ngIf="network.wpa2ssid">
        <div class="key">{{ 'health.networkInformation.wpa2ssid' | translate }}</div>
        <div class="controls solo">
          <div class="pill" [class.disabled]="!permissions?.uiFeatures.editSSID" (click)="openNetworkSettings('wpa2')">
            <div class="label pre">
              <div>{{ network.wpa2ssid || ('health.networkInformation.na' | translate) }}</div>
              <icon name="icon-edit" *ngIf="permissions?.uiFeatures.editSSID"></icon>
            </div>
          </div>
          <icon
            class="recommendation"
            name="icon-alert-warning"
            tooltip="{{ 'health.networkInformation.badgeWarning' | translate }}"
            *ngIf="WPA3Recommendation$ | async"
          ></icon>
        </div>
      </div>
      <div class="row" [class.disabled]="!network.wpa3enabled" *ngIf="network.wpa3ssid">
        <div class="key">{{ 'health.networkInformation.wpa3ssid' | translate }}</div>
        <div class="controls solo">
          <div class="pill" [class.disabled]="!permissions?.uiFeatures.editSSID" (click)="openNetworkSettings('wpa3')">
            <div class="label pre">
              <div>{{ network.wpa3ssid || ('health.networkInformation.na' | translate) }}</div>
              <icon name="icon-edit" *ngIf="permissions?.uiFeatures.editSSID"></icon>
            </div>
          </div>
          <icon
            class="recommendation"
            name="icon-alert-warning"
            tooltip="{{ 'health.networkInformation.badgeWarning' | translate }}"
            *ngIf="WPA3Recommendation$ | async"
          ></icon>
        </div>
      </div>
      <div
        class="row"
        [class.disabled]="!secondaryNetworksData?.secondary?.enable"
        *ngIf="secondaryNetworksData?.secondary?.ssid"
      >
        <div class="key">{{ 'health.networkInformation.ssidSecondary' | translate }}</div>
        <div class="controls solo">
          <div
            class="pill"
            [class.disabled]="!permissions?.uiFeatures.editSSID"
            (click)="openNetworkSettings('secondary')"
          >
            <div class="label pre">
              <div>{{ secondaryNetworksData?.secondary?.ssid || ('health.networkInformation.na' | translate) }}</div>
              <icon name="icon-edit" *ngIf="permissions?.uiFeatures.editSSID"></icon>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="key">{{ 'techdash.infoArea.optimization' | translate }}</div>
        <div class="controls solo">
          <div
            class="pill"
            [class.disabled]="!validateOptimization() || (enableOptimizationButton$ | async) === false"
            (click)="triggerOptimize()"
            *ngIf="optimizeState !== 'loading' && nodesPool?.length > 0"
          >
            <div class="label">
              {{ getOptimizationLabel() | translate }}
              <icon name="icon-refresh" class="size-0_8" [class.busy]="optimizeState === 'running'"></icon>
            </div>
            <div class="progressbar" *ngIf="optimizeState === 'running'"><div class="indeterminate"></div></div>
          </div>
          <div class="disabledpill" *ngIf="optimizeState !== 'loading' && nodesPool?.length < 1">
            {{ 'techdash.infoArea.noNodes' | translate }}
          </div>
          <div class="disabledpill" *ngIf="optimizeState === 'loading' && nodesPool?.length < 1">
            {{ 'configurations.loading' | translate }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="key">{{ 'techdash.infoArea.onboarding' | translate }}</div>
        <div class="controls solo">
          <div
            class="pill"
            (click)="triggerOnboarding(location)"
            [class.incomplete]="!onboarded"
            [class.disabled]="onboarded || isSupportRole()"
          >
            <div class="label">{{ onboardingStatus | translate }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="key">{{ 'techdash.infoArea.export' | translate }}</div>
        <div class="controls solo">
          <div class="pill" [class.disabled]="nodesPool?.length < 1" (click)="exportCSV()">
            <div class="label">
              <div>{{ 'techdash.infoArea.report' | translate }}</div>
              <icon name="icon-download" class="size-0_8"></icon>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="key">{{ 'techdash.infoArea.firmware' | translate }}</div>
        <div class="controls solo">
          <div
            class="pill"
            (click)="upgradeFirmware()"
            [class.incomplete]="
              (firmware.upgrade || firmware.upgradePostOnboarding) && permissions?.uiFeatures.firmwareUpgrade
            "
            [class.disabled]="
              (!firmware.upgrade && !firmware.upgradePostOnboarding) || !permissions?.uiFeatures.firmwareUpgrade
            "
          >
            <div class="label">{{ firmware.upgradeText | translate }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="row">
        <div class="key">{{ 'techdash.infoArea.email' | translate }}</div>
        <div class="value" *ngIf="!setEmail">
          <div class="ellipsis" [tooltip]="(customer$ | async)?.email | email" [onlyOverflowText]="true">
            {{ (customer$ | async)?.email | email }}
          </div>
        </div>
        <div class="value" *ngIf="setEmail">
          <input
            (keyup.enter)="updateEmail()"
            (keyup.escape)="toggleEditEmail()"
            [formControl]="emailFormControl"
            autofocus
          />
          <div class="validation" *ngIf="!validateEmail()">
            {{ 'techdash.infoArea.emailValidation' | translate }}
          </div>
        </div>
        <div class="controls">
          <icon
            name="icon-edit"
            tooltip="{{ 'techdash.tooltip.editEmail' | translate }}"
            (click)="toggleEditEmail()"
            *ngIf="!setEmail && permissions?.uiFeatures.editNameEmailLocation && !isUprise"
          ></icon>
          <div class="edit" *ngIf="setEmail">
            <icon
              name="icon-check"
              class="confirm"
              [class.disabled]="!validateEmail()"
              tooltip="{{ 'techdash.tooltip.set' | translate }}"
              (click)="updateEmail()"
            ></icon>
            <icon
              name="icon-close"
              class="size-0_8 cancel"
              tooltip="{{ 'techdash.tooltip.cancel' | translate }}"
              (click)="toggleEditEmail()"
            ></icon>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="key">{{ 'techdash.infoArea.name' | translate }}</div>
        <div class="value" *ngIf="!setName">
          <div class="ellipsis" [tooltip]="(customer$ | async)?.name | customerName" [onlyOverflowText]="true">
            {{ (customer$ | async)?.name | customerName }}
          </div>
        </div>
        <div class="value" *ngIf="setName">
          <input
            (keyup.enter)="updateName()"
            (keyup.escape)="toggleEditName()"
            [formControl]="nameFormControl"
            autofocus
            trimValue
          />
          <div class="validation" *ngIf="!validateName()">
            {{ 'techdash.infoArea.nameValidation' | translate }}
          </div>
        </div>
        <div class="controls">
          <icon
            name="icon-edit"
            tooltip="{{ 'techdash.tooltip.editName' | translate }}"
            (click)="toggleEditName()"
            *ngIf="!setName && permissions?.uiFeatures.editNameEmailLocation && !isUprise"
          ></icon>
          <div class="edit" *ngIf="setName">
            <icon
              name="icon-check"
              class="confirm"
              [class.disabled]="!validateName()"
              tooltip="{{ 'techdash.tooltip.set' | translate }}"
              (click)="updateName()"
            ></icon>
            <icon
              name="icon-close"
              class="size-0_8 cancel"
              tooltip="{{ 'techdash.tooltip.cancel' | translate }}"
              (click)="toggleEditName()"
            ></icon>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="key">{{ 'techdash.infoArea.accountId' | translate }}</div>
        <div class="value" *ngIf="!setAccountId">
          <span class="ellipsis" [tooltip]="(customer$ | async)?.accountId" [onlyOverflowText]="true">
            {{ (customer$ | async)?.accountId }}
          </span>
        </div>
        <div class="value" *ngIf="setAccountId">
          <input
            (keyup.enter)="updateAccountId()"
            (keyup.escape)="toggleEditAccountId()"
            [formControl]="accountIdFormControl"
            autofocus
          />
          <div class="validation" *ngIf="!validateAccountId()">
            {{ 'techdash.infoArea.accountIdValidation' | translate }}
          </div>
        </div>
        <div class="controls">
          <icon
            name="icon-edit"
            tooltip="{{ 'techdash.tooltip.editAccountId' | translate }}"
            (click)="toggleEditAccountId()"
            *ngIf="!setAccountId && permissions?.uiFeatures.editAccountId && !isUprise"
          ></icon>
          <div class="edit" *ngIf="setAccountId">
            <icon
              name="icon-check"
              class="confirm"
              [class.disabled]="!validateAccountId()"
              tooltip="{{ 'techdash.tooltip.set' | translate }}"
              (click)="updateAccountId()"
            ></icon>
            <icon
              name="icon-close"
              class="size-0_8 cancel"
              tooltip="{{ 'techdash.tooltip.cancel' | translate }}"
              (click)="toggleEditAccountId()"
            ></icon>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isUprise">
        <div class="key">{{ 'techdash.infoArea.uprise' | translate }}</div>
        <div class="value">
          <button *ngIf="getUpriseUrl()" (click)="openUprise()">
            <icon name="icon-external" class="link-icon"></icon>
          </button>
        </div>
      </div>
      <div class="row" *ngIf="plume.isGroupSupportTechnician() && (locationList$ | async)?.length > 1">
        <div class="key">{{ 'techdash.infoArea.locations' | translate }}</div>
        <div class="value">
          <drop-down class="selector medium">
            {{ location.name }}
            <ng-container dropdown>
              <ng-container *ngFor="let selectLocation of locationList$ | async">
                <li (click)="changeLocation(selectLocation)" *ngIf="selectLocation.id !== location.id">
                  {{ selectLocation.name }}
                </li>
              </ng-container>
            </ng-container>
          </drop-down>
        </div>
      </div>
    </div>
  </div>

  <div class="title nodes remSpacer" *ngIf="nodesPool?.length > 0">
    {{ 'techdash.nodeArea.nodesClaimed' | translate }}
  </div>

  <ng-container *ngIf="loadingNodes">
    <div class="loading">{{ 'configurations.loading' | translate }}</div>
  </ng-container>
  <ng-container *ngIf="!loadingNodes">
    <div *ngIf="nodesPool?.length > 0">
      <div class="stripHeader nodestrip">
        <div class="stripCell status">{{ 'techdash.nodeArea.status' | translate }}</div>
        <div class="stripCell info">{{ 'techdash.nodeArea.nodeName' | translate }}</div>
        <div class="stripCell rssi">{{ 'techdash.nodeArea.backhaulRssi' | translate }}</div>
        <div class="stripCell health">{{ 'techdash.nodeArea.healthRating' | translate }}</div>
        <div class="stripCell speed">{{ 'techdash.nodeArea.speedTest' | translate }}</div>
        <div class="stripCell controls"></div>
      </div>
      <nodestrip [node]="node" (delete)="deleteNode($event)" *ngFor="let node of nodes; trackBy: track"></nodestrip>
    </div>
    <div class="add-node-wrap" *ngIf="!plume.isFlexRole()">
      <addnode (nodeAdded)="nodeAdded($event)"></addnode>
    </div>
  </ng-container>
</div>

<modal (overlayAction)="firmware.modal = $event" *ngIf="firmware.modal">
  <ng-container title>{{ 'techdash.modal.firmwareUpgrade' | translate }}</ng-container>
  <ng-container content>
    <div class="general">
      <div class="status">{{ 'techdash.modal.locationStatus' | translate }}: {{ firmware.state }}</div>
    </div>
    <div class="node" *ngFor="let node of firmware.nodes">
      <div class="data">
        <icon path="assets/icons/{{ node.model?.kind?.icon }}.svg"></icon>
        <div class="name">{{ node.model?.nickname || node.model?.name || node.model?.defaultName }}</div>
      </div>
      <div class="firmware">{{ 'techdash.modal.FW' | translate }}: {{ node.firmware }}</div>
      <div class="status">{{ 'techdash.modal.status' | translate }}: {{ node.state }}</div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeModal()">{{ 'techdash.modal.close' | translate }}</button>
  </ng-container>
</modal>

<modal (overlayAction)="showNetworkSettings = $event" *ngIf="showNetworkSettings">
  <ng-container content>
    <networksettings [advancedOptions]="false" [mode]="networkSettingsMode"></networksettings>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeAndRefreshNetworkSettings()">
      {{ 'techdash.modal.close' | translate }}
    </button>
  </ng-container>
</modal>
