import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/lib/services/api.service';
import { AuthService } from 'src/app/lib/services/auth.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { OktaService } from 'src/app/lib/services/okta.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { environment } from 'src/environments/environment';
import { generateOktaConfig, hostNameParse } from 'src/environments/shared/okta';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  ready: boolean = false;
  email: UntypedFormControl = new UntypedFormControl();
  emailUnlock: UntypedFormControl = new UntypedFormControl();
  password: UntypedFormControl = new UntypedFormControl();
  passCode: UntypedFormControl = new UntypedFormControl();
  loading: boolean = false;
  loadingMfa: boolean = false;
  error: any = {
    show: false,
    message: ''
  };
  globalDomain: boolean = true;
  env: any = {
    selected: {},
    list: [],
    selectedIdpIndex: 0
  };
  handler: string = '/login/handle';
  versionString: string;
  environment: any = environment;
  applicationName: string;
  mfa: any = null;
  mfaTimeout: any;
  mfaPushInterval: any;
  mfaResendTimeout: any;
  mfaCanResend: boolean = false;
  mfaUnlockAccount: any = null;
  mfaEnroll: any = {
    show: false,
    data: null
  };

  logo: string = '';
  pattern: string = '';
  showIdp: boolean = false;
  count: number = 5;
  idpTimeout: any;
  queryParamsSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private auth: AuthService,
    private plume: PlumeService,
    private okta: OktaService,
    private toast: ToastService,
    private mixpanel: MixpanelService
  ) {}

  ngOnInit(): void {
    const ui = this.plume.getUI() === 'noc' ? '23' : '1';
    const vendor = this.plume.getVendor();

    this.logo = 'assets/logo/' + vendor + '_frontline_tier' + ui + '.svg';

    const error = this.plume.getObject('error', false);
    this.plume.removeObject('error');

    this.queryParamsSubscription = this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.error) {
        const { error, ...newQueryParams } = queryParams;
        this.showErrorByName(error);
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { ...newQueryParams },
          preserveFragment: true,
          skipLocationChange: true
        });
      }
    });

    const { authKey, customerId, hostname, ttl } = this.route.snapshot.queryParams;

    if (authKey && customerId && hostname && ttl) {
      this.router.navigate(['/login', 'handle'], { queryParams: { authKey, customerId, hostname, ttl } });
    }

    if (this.auth.isAuthenticated()) {
      if (this.plume.customerid && this.plume.locationid) {
        this.router.navigate(['/customer', this.plume.customerid, 'location', this.plume.locationid]);
      } else {
        this.router.navigate(['/']);
      }
    } else {
      this.mixpanel.clearUserAndCustomerDetails();

      if (error) {
        this.showErrorByName(error);
      }

      let idpSubset: any = [];
      let preselectedEnv: any = {};

      const env = this.auth.getEnvFromUrl();

      if (env) {
        preselectedEnv = env;

        this.globalDomain = false;
        this.env.list = [];
        idpSubset = this.getEnvSubdomainDetails('idps', preselectedEnv);
        preselectedEnv.loopbackAuth = this.getEnvSubdomainDetails('loopbackAuth', preselectedEnv);
        preselectedEnv.oktaAuth = this.getEnvSubdomainDetails('oktaAuth', preselectedEnv);
      } else {
        this.env.list = this.environment.environments;
        preselectedEnv = this.environment.environments[Object.keys(this.environment.environments)[0]];
      }

      if (idpSubset) {
        if (idpSubset.length > 0) {
          preselectedEnv.idps = idpSubset.map((i: number) => preselectedEnv.idps[i]);
        }
      } else {
        delete preselectedEnv.idps;
      }

      this.selectEnv(preselectedEnv);

      if (!this.globalDomain) {
        if (window.history.state.okta !== 'logout') {
          this.okta.checkSession(
            (mode: string) => {
              this.oktaLogin(mode);
            },
            () => {
              this.ready = true;

              const { idpValue, idpId, idpMode } = this.route.snapshot.queryParams;

              if (idpValue && idpId) {
                const idp = { id: idpId, value: idpValue, mode: idpMode || 'domain' };
                this.plume.saveObject('idp', idp, true, true);
                this.idpModal(idp, 'params');
              } else {
                const idp = this.plume.getObject('idp', true, true) || null;

                if (idp) {
                  this.idpModal(idp, 'saved');
                }
              }
            }
          );
        } else {
          this.ready = true;
        }
      } else {
        this.ready = true;
      }
    }

    this.versionString = this.plume.getVersion();
    this.applicationName = this.plume.getUI() === 'noc' ? 'Frontline Tier 2 & 3' : 'Frontline Tier 1';
  }

  showErrorByName(errorName: string): void {
    switch (errorName) {
      case 'intercepted401':
        this.toast.error('toast.interceptor.errorMessage', 'toast.interceptor.errorTitle', {});
        this.mixpanel.storeEvent('AUTHENTICATION_FAILURE', { ERROR_TEXT: '401' });
        break;
      case 'Identity+provider+is+not+valid.':
        this.toast.error('toast.idp.errorMessage', 'toast.idp.errorTitle', {});
        this.mixpanel.storeEvent('AUTHENTICATION_FAILURE', { ERROR_TEXT: 'IDP_NOT_VALID' });
        this.cancelIdp();
        break;
      case 'User+is+not+assigned+to+the+client+application.':
        this.error.message = 'login.error.notAssignedToClientApp';
        this.error.show = true;
        this.mixpanel.storeEvent('AUTHENTICATION_FAILURE', { ERROR_TEXT: 'NOT_ASSIGNED_TO_CLIENT_APP' });
        break;
      case 'role':
        this.error.message = 'login.error.role';
        this.error.show = true;
        this.mixpanel.storeEvent('AUTHENTICATION_FAILURE', { ERROR_TEXT: 'ROLE' });
        break;
      case 'publicApiAccess':
        this.error.message = 'login.error.publicApiAccess';
        this.error.show = true;
        this.mixpanel.storeEvent('AUTHENTICATION_FAILURE', { ERROR_TEXT: 'PUBLIC_API_ACCESS' });
        break;
      case 'introspectApiAccess':
        this.error.message = 'login.error.introspectApiAccess';
        this.error.show = true;
        this.mixpanel.storeEvent('AUTHENTICATION_FAILURE', { ERROR_TEXT: 'INTROSPECT_API_ACCESS' });
        break;
      case 'introspectUnauthorized':
        this.error.message = 'login.error.role';
        this.error.show = true;
        this.mixpanel.storeEvent('AUTHENTICATION_FAILURE', { ERROR_TEXT: 'INTROSPECT_UNAUTHORIZED' });
        break;
      case 'noSiteAccess':
        this.error.message = 'login.error.noSiteAccess';
        this.error.show = true;
        this.mixpanel.storeEvent('AUTHENTICATION_FAILURE', { ERROR_TEXT: 'NO_SITE_ACCESS' });
        break;
      case 'noEnvFound':
        this.error.message = 'login.error.noEnvFound';
        this.error.show = true;
        this.mixpanel.storeEvent('AUTHENTICATION_FAILURE', { ERROR_TEXT: 'NO_ENV_FOUND' });
        break;
      case 'unknown':
        this.error.message = 'login.error.unknown';
        this.error.show = true;
        this.mixpanel.storeEvent('AUTHENTICATION_FAILURE', { ERROR_TEXT: 'UNKNOWN' });
        break;
      default:
        this.error.message = 'login.error.default';
        this.error.show = true;
        this.mixpanel.storeEvent('AUTHENTICATION_FAILURE', { ERROR_TEXT: 'DEFAULT' });
    }
  }

  selectEnv(env: any): void {
    this.env.selected = env;
    this.env.list = [env, ...this.env.list.filter((item: any) => item.name !== env.name)];
    this.environment.okta = generateOktaConfig(env.id);
    this.plume.setEnv({ hostname: env.url, id: env.id, lteUrl: env.lteUrl, flexUrl: env.flexUrl });
  }

  redirect(): void {
    window.location.replace(window.location.protocol + '//' + this.env.selected.id + '.' + window.location.host);
  }

  selectIdp(idpIndex: number): void {
    this.env.selectedIdpIndex = idpIndex;
  }

  getEnvSubdomainDetails(param: string, preselectedEnv: any): any {
    const { partner, group } = hostNameParse();

    if (partner) {
      if (preselectedEnv.partners[partner]) {
        if (group) {
          if (preselectedEnv.partners[partner].groups && preselectedEnv.partners[partner].groups[group]) {
            return preselectedEnv.partners[partner].groups[group][param] || false;
          }
        }
        return preselectedEnv.partners[partner][param] || false;
      }
    }
    if (preselectedEnv[param] instanceof Array) {
      return Object.keys(preselectedEnv[param]) || false;
    } else {
      return preselectedEnv[param] || false;
    }
  }

  resetForm(): void {
    this.email.reset();
    this.password.reset();
    this.error = {
      show: false,
      message: ''
    };
  }

  idpModal(idp: any, started: string): void {
    if (idp) {
      this.mixpanel.storeEvent('IDP_MODAL', {
        IDP_ID: idp.id,
        IDP_VALUE: idp.value,
        STARTED_FROM: started
      });
      this.showIdp = true;
      this.countdown(idp);
    }
  }

  countdown(idp: any): void {
    this.idpTimeout = setTimeout(() => {
      if (this.count > 1) {
        this.count--;
        this.countdown(idp);
      } else {
        this.cloudLogin(idp);
      }
    }, 1000);
  }

  cancelIdp(): void {
    this.showIdp = false;
    this.plume.removeObject('idp');
    clearTimeout(this.idpTimeout);
  }

  login(): void {
    this.loading = true;
    this.error = {
      show: false,
      message: ''
    };

    if (this.env && this.env.selected && !this.env.selected.loopbackAuth) {
      this.mixpanel.storeEvent('AUTHENTICATION_ATTEMPT', {
        AUTHENTICATION_TYPE: 'LOCAL',
        USERNAME: this.email.value
      });

      this.error.message = 'Development System please use ' + this.env.selected.id + '.central.plume.com';
      this.loading = false;
      this.error.show = true;
      return;
    }

    this.api
      .raw(
        'post',
        this.api.hostname() + '/api/Customers/login',
        {
          email: this.email.value,
          password: this.password.value
        },
        { headers: { 'Plume-Application-Name': 'PlumeCentral' } }
      )
      .subscribe(
        (response: any) => {
          const env = this.auth.getEnvFromUrl();
          const ttl = response.expireAt ? new Date(response.expireAt).getTime() : null;
          const params =
            '/login/handle?authKey=' +
            response.id +
            '&customerId=' +
            response.userId +
            '&hostname=' +
            this.env.selected.url.substr(this.env.selected.url.indexOf('//') + 2) +
            (ttl ? '&ttl=' + ttl : '') +
            (this.env.selected.url.indexOf('http://') > -1 ? '&mixedProtocol=true' : '');

          this.plume.saveObject('authType', { AUTHENTICATION_TYPE: 'LOCAL', USERNAME: this.email.value });

          if (this.env.selected.id === env?.id || this.environment.ENV === 'Local') {
            window.location.replace(window.location.origin + params);
          } else {
            window.location.replace(
              window.location.protocol + '//' + this.env.selected.id + '.' + window.location.host + params
            );
          }
        },
        (error: any) => {
          if (error.error.error) {
            switch (error.error.error.code) {
              case 'USERNAME_EMAIL_REQUIRED':
                this.error.message = 'login.error.emailRequired';
                break;
              case 'LOGIN_FAILED':
                this.error.message = 'login.error.wrongCredentials';
                break;
              default:
                this.error.message = 'login.error.default';
            }
          } else {
            this.error.message = 'login.error.default'; // CORS error for example
          }

          this.loading = false;
          this.error.show = true;
        }
      );
  }

  cloudLogin(idp: any): void {
    if (idp) {
      const env = this.auth.getEnvFromUrl();

      if (idp.id === 'okta') {
        let mode = 'domain';

        if (idp.mode) {
          mode = idp.mode;
          this.okta.mode = mode;
        }
        const idpsToForceUseVanity = [''];
        if (idpsToForceUseVanity.includes(idp.value)) {
          mode = 'vanity';
          this.okta.mode = mode;
        }

        const { nonce, state } = this.okta.setSessionCookie(this.env.selected.url.substr(8), mode);
        const href =
          this.env.selected.id === env?.id || this.environment.ENV === 'Local'
            ? window.location.origin + this.handler
            : window.location.protocol + '//' + this.env.selected.id + '.' + window.location.host + this.handler;

        this.plume.saveObject('authType', {
          AUTHENTICATION_TYPE: 'OKTASAML',
          IDP_VALUE: idp.value,
          IDP_MODE: idp.mode
        });

        this.okta.authorize(href, nonce, state, null, idp.value);
      } else {
        const provisioningUrlSuffix = '/plumeUser/' + idp.id + '/login';

        const href = this.env.selected.url + this.environment.server.endpoint['provisioning'] + provisioningUrlSuffix;
        const hrefParams =
          this.env.selected.id === env?.id || this.environment.ENV === 'Local'
            ? '?relay_state=' + window.location.origin + this.handler
            : '?relay_state=' +
              window.location.protocol +
              '//' +
              this.env.selected.id +
              '.' +
              window.location.host +
              this.handler;

        this.plume.saveObject('authType', { AUTHENTICATION_TYPE: 'SAML', IDP: idp.id });

        window.location.replace(href + hrefParams);
      }
    }
  }

  oktaLogin(mode: string = null): void {
    const env = this.auth.getEnvFromUrl();

    if (!mode) {
      mode = this.env.selected.oktaMode ? this.env.selected.oktaMode : 'domain';
    }

    this.okta.mode = mode;

    const { nonce, state } = this.okta.setSessionCookie(this.env.selected.url.substr(8), mode);
    const href =
      this.env.selected.id === env?.id || this.environment.ENV === 'Local'
        ? window.location.origin + this.handler
        : window.location.protocol + '//' + this.env.selected.id + '.' + window.location.host + this.handler;

    this.plume.saveObject('authType', { AUTHENTICATION_TYPE: 'OKTA' });

    this.okta.authorize(href, nonce, state);
  }

  ngOnDestroy(): void {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }
}

@Component({ template: '' })
export class LoginHandleComponent {
  constructor(
    private route: ActivatedRoute,
    private plume: PlumeService,
    private okta: OktaService,
    private auth: AuthService,
    private api: ApiService
  ) {
    const { authKey, customerId, hostname, ttl, iss, mixedProtocol } = this.route.snapshot.queryParams;
    const env = this.auth.getEnvFromUrl();

    if (window.location.hash.length) {
      const { token, user, hostname, ttl, error } = this.okta.handleLogin();

      if (error) {
        this.auth.logout({ error });
      } else if (token && user) {
        this.plume.setEnv(hostname ? { hostname: 'https://' + hostname } : env.url);
        this.auth.setOktaToken(token, false);
        this.auth.storeSession(user, hostname ? 'https://' + hostname : env.url, ttl);
      } else {
        this.checkSession(env);
      }
    } else if (authKey && customerId && hostname) {
      const protocol: string = mixedProtocol ? 'http://' : 'https://';
      this.handleLogin(authKey, customerId, hostname ? protocol + hostname : env.url, ttl ? ttl : null);
    } else if (iss) {
      this.checkSession(env);
    } else {
      this.auth.logout({ error: 'unknown' });
    }
  }

  checkSession(env: any): void {
    if (env) {
      this.okta.checkSession(
        () => {
          this.okta.getToken((data: any) => {
            if (data) {
              this.plume.setEnv({ hostname: env.url });
              this.auth.setOktaToken(data.token, false);
              this.auth.storeSession(data.user, env.url, data.ttl);
            } else {
              this.auth.logout({ error: 'unknown' });
            }
          });
        },
        () => {
          this.auth.logout({ error: 'unknown' });
        }
      );
    } else {
      this.auth.logout({ error: 'noEnvFound' });
    }
  }

  handleLogin(token: string, userid: string, hostname: string, ttl: number = null): void {
    this.plume.setEnv({ hostname });
    this.auth.setToken(token, false);

    this.api.get('/Customers/' + userid).subscribe(
      (user: any) => {
        this.auth.storeSession(user, hostname, ttl);
      },
      () => {
        this.auth.logout({ error: 'unknown' });
      }
    );
  }
}
