<div class="help" *ngIf="helpPage && helpPage?.helpItems">
  <div
    class="helpButton"
    [class.disabled]="helpDisabled"
    [class.expanded]="expanded"
    [tooltip]="'help.tooltip' | translate"
    (click)="toggleHelp()"
  >
    <icon name="icon-close" *ngIf="!searchingForElement" [@animation]="expanded ? 'expanded' : 'collapsed'"></icon>
    <div class="spinner">
      <icon name="icon-spinner" *ngIf="searchingForElement" [@animation]="expanded ? 'expanded' : 'collapsed'"></icon>
    </div>
    <icon name="icon-question" class="icon-question" [@animation]="expanded ? 'collapsed' : 'expanded'"></icon>
  </div>

  <div class="startOverlay" [@overlayAnimation]="startExpanded ? 'expanded' : 'collapsed'">
    <div class="startOverlayContent">
      <div class="startHelpContainer">
        <div class="title">{{ 'help.title' | translate }}</div>
        <div class="startContent">
          <div class="startItems" *ngIf="helpItemsLength > 0">
            <ng-container *ngFor="let item of helpItemNames">
              <div class="startButton" (click)="helpItemsVisible[item].visible && startDemo(item)">
                <button
                  class="super-primary startButtonLabel"
                  [disabled]="!helpItemsVisible[item].visible"
                  (click)="helpItemsVisible[item].visible && startDemo(item)"
                >
                  {{ 'help.items.' + item | translate }}
                </button>
                <div class="startButtonHint" *ngIf="!helpItemsVisible[item].visible">
                  {{ helpItemsVisible[item].reason | translate }}
                </div>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="helpItemsLength === 0">
            <div class="noItems">{{ 'help.noItems' | translate }}</div>
          </ng-container>
        </div>
      </div>
      <div class="startFooter">
        <div class="button">
          <button class="action textCenter" (click)="openFeedbackModal.emit()">
            {{ 'help.feedback.button' | translate }}
          </button>
        </div>
        <div class="startFeedbackLabel">{{ 'help.feedback.label' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="overlay" [@overlayAnimation]="inProgress ? 'expanded' : 'collapsed'" (click)="consumeClick($event)">
    <div class="spotlightShadow" [ngStyle]="spotlightStyle"></div>
    <div class="spotlight" [ngStyle]="spotlightStyle"></div>

    <div class="dialog" *ngIf="currentStep" [ngStyle]="spotlightStyle">
      <div class="dialog-content" #dialog [ngStyle]="dialogStyle">
        <div class="dialog-text">
          {{ currentStep.content | translate }}
        </div>
        <div class="dialog-buttons">
          <button class="tertiary light small" (click)="stopDemo()">Close</button>
          <div class="dialog-actionButtons">
            <button class="tertiary light small" (click)="previousStep()" *ngIf="currentStepIndex !== 0">
              Previous
            </button>
            <button class="action small" (click)="nextStep()">
              {{ currentStepIndex === stepsLength - 1 ? 'Finish' : 'Next' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
