import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IDevice, IDeviceQoe } from 'src/app/lib/interfaces/interface';
import { PlumeService } from 'src/app/lib/services/plume.service';

@Component({
  selector: 'qoe-modal',
  templateUrl: './qoe-modal.component.html',
  styleUrls: ['./qoe-modal.component.scss']
})
export class QoeModalComponent implements OnInit {
  @Input()
  device: IDevice & { rawQoe?: IDeviceQoe } = null;

  @Output()
  closeQoeModal = new EventEmitter<void>();

  isMlo = false;
  mloRadioIndexSelected = 0;
  mloRadios: any = null;
  linkDataKeys = [
    'availableAirtime',
    'channelUtilization',
    'freqBand',
    'interference',
    'chWidth',
    'noiseFloor',
    'rssi',
    'snr',
    'rxPrr',
    'txPrr',
    'rxAirtime',
    'txAirtime',
    'nss',
    'mcs',
    'mac',
    'mlo',
    'maxCapabilityBasedPhyRate'
  ];

  constructor(private plume: PlumeService, private router: Router) {}
  ngOnInit(): void {
    this.buildMloRadios();
    this.isMlo = this.device?.rawQoe?.mldAddr ? true : false;
  }

  buildMloRadios() {
    this.mloRadios = [];

    if (this.device?.rawQoe?.mlo?.links?.find((ch) => ch.freqBand === '2.4G')) {
      this.mloRadios.push({
        translation: '2.4G',
        value: '2.4G',
        selected: false
      });
    }
    if (this.device?.rawQoe?.mlo?.links?.find((ch) => ch.freqBand === '5G')) {
      this.mloRadios.push({
        translation: '5G',
        value: '5G',
        selected: false
      });
    }
    if (this.device?.rawQoe?.mlo?.links?.find((ch) => ch.freqBand === '5GU')) {
      this.mloRadios.push({
        translation: '5GU',
        value: '5GU',
        selected: false
      });
    }
    if (this.device?.rawQoe?.mlo?.links?.find((ch) => ch.freqBand === '6G')) {
      this.mloRadios.push({
        translation: '6G',
        value: '6G',
        selected: false
      });
    }
    if (this.mloRadios.length > 0) {
      this.mloRadios[0].selected = true;
      this.setMLORadio(this.mloRadios[0].value);
    }
  }

  setMLORadio(value: string): void {
    this.mloRadioIndexSelected = this.device.rawQoe.mlo.links.findIndex((ch) => ch.freqBand === value);
  }

  isLinkData(key: string): boolean {
    if (this.linkDataKeys.includes(key)) {
      return true;
    }
    return false;
  }

  gotoQoe() {
    this.router.navigate([
      'customer',
      this.plume.customerid,
      'location',
      this.plume.locationid,
      'qoe',
      'devices',
      this.device.mac
    ]);
  }

  closeModal() {
    this.closeQoeModal.emit();
  }
}
