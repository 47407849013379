import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from 'src/app/lib/services/api.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { LteService } from 'src/app/lib/services/lte.service';
import { Bar } from 'src/app/lib/d3/models/objects/bar';
import { generateBars } from '../speedtestnode/speedtestnode-bar-generator';
import { filter, map, switchMap } from 'rxjs/operators';
import { ToastService } from 'src/app/lib/services/toast.service';
import { Store } from '@ngrx/store';
import { selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { selectLteInUse } from 'src/app/store/lte/lte.selectors';
import { speedTestConfigure } from 'src/app/store/speed-test/speed-test.actions';
import { DeepReadonly, ILocation } from 'src/app/lib/interfaces/interface';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';

@UntilDestroy()
@Component({
  selector: 'speedtestcontrols',
  templateUrl: './speedtestcontrols.component.html',
  styleUrls: ['./speedtestcontrols.component.scss']
})
export class SpeedtestControlsComponent implements OnInit, OnDestroy {
  permissionsSubscription: any;
  permissions: any;
  enableISP: boolean = false;
  enableAll: boolean = false;
  lteRange: {
    start: string;
    end: string;
    mode: '7d' | '24h';
  } = { start: new Date().toISOString(), end: new Date().toISOString(), mode: '7d' };
  lteBars: Bar[] = [];
  lteSupported$ = this.store.select(selectCapabilities).pipe(map((capabilities) => capabilities?.lte?.capable));
  lteInUse$ = this.store.select(selectLteInUse);
  showLteUpload = true;

  dataDownload: any = [
    { label: '1000+', value: 0 },
    { label: '750 - 1000', value: 0 },
    { label: '500 - 750', value: 0 },
    { label: '250 - 500', value: 0 },
    { label: '150 - 250', value: 0 },
    { label: '75 - 150', value: 0 },
    { label: '30 - 75', value: 0 },
    { label: '10 - 30', value: 0 },
    { label: '5 - 10', value: 0 },
    { label: '0 - 5', value: 0 }
  ];

  dataUpload: any = [
    { label: '300+', value: 0 },
    { label: '200 - 300', value: 0 },
    { label: '100 - 200', value: 0 },
    { label: '50 - 100', value: 0 },
    { label: '25 - 50', value: 0 },
    { label: '10 - 25', value: 0 },
    { label: '5 - 10', value: 0 },
    { label: '0 - 5', value: 0 }
  ];

  constructor(
    private api: ApiService,
    public plume: PlumeService,
    private toast: ToastService,
    private store: Store,
    private lteService: LteService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    this.permissionsSubscription = this.plume.permissions.subscribe((data: any) => {
      this.permissions = data;
    });

    this.getISPresults();
    this.lteSpeed();

    this.store.pipe(selectPipeLocationOnChange, untilDestroyed(this)).subscribe((location) => {
      this.initTogglers(location);
    });
  }

  initTogglers(location: DeepReadonly<ILocation>): void {
    this.enableISP = location.ispSpeedTestConfiguration?.enable;
    this.enableAll = location.ispSpeedTestConfiguration?.enableAllNodes;
  }

  change(event: any): void {
    const conf = {
      enable: this.enableISP,
      enableAllNodes: this.enableAll
    };

    switch (event) {
      case 'isp':
        conf.enable = !this.enableISP;
        break;
      case 'all':
        conf.enableAllNodes = !this.enableAll;
        break;
    }
    this.store.dispatch(speedTestConfigure({ configuration: conf }));
  }

  getISPresults(): void {
    this.api
      .get('/Customers/' + this.plume.customerid + '/locations/' + this.plume.locationid + '/appFacade/dashboard')
      .subscribe((response: any) => {
        const dataDownload: any = [
          { label: '1000+', value: 0 },
          { label: '750 - 1000', value: 0 },
          { label: '500 - 750', value: 0 },
          { label: '250 - 500', value: 0 },
          { label: '150 - 250', value: 0 },
          { label: '75 - 150', value: 0 },
          { label: '30 - 75', value: 0 },
          { label: '10 - 30', value: 0 },
          { label: '5 - 10', value: 0 },
          { label: '0 - 5', value: 0 }
        ];

        const dataUpload: any = [
          { label: '300+', value: 0 },
          { label: '200 - 300', value: 0 },
          { label: '100 - 200', value: 0 },
          { label: '50 - 100', value: 0 },
          { label: '25 - 50', value: 0 },
          { label: '10 - 25', value: 0 },
          { label: '5 - 10', value: 0 },
          { label: '0 - 5', value: 0 }
        ];

        response.month.chart.forEach((data: any) => {
          if (data.download > 0 && data.download <= 5) {
            dataDownload[9].value++;
          }
          if (data.download > 5 && data.download <= 10) {
            dataDownload[8].value++;
          }
          if (data.download > 10 && data.download <= 30) {
            dataDownload[7].value++;
          }
          if (data.download > 30 && data.download <= 75) {
            dataDownload[6].value++;
          }
          if (data.download > 75 && data.download <= 150) {
            dataDownload[5].value++;
          }
          if (data.download > 150 && data.download <= 250) {
            dataDownload[4].value++;
          }
          if (data.download > 250 && data.download <= 500) {
            dataDownload[3].value++;
          }
          if (data.download > 500 && data.download <= 750) {
            dataDownload[2].value++;
          }
          if (data.download > 750 && data.download <= 1000) {
            dataDownload[1].value++;
          }
          if (data.download > 1000) {
            dataDownload[0].value++;
          }

          if (data.upload > 0 && data.upload <= 5) {
            dataUpload[7].value++;
          }
          if (data.upload > 5 && data.upload <= 10) {
            dataUpload[6].value++;
          }
          if (data.upload > 10 && data.upload <= 25) {
            dataUpload[5].value++;
          }
          if (data.upload > 25 && data.upload <= 50) {
            dataUpload[4].value++;
          }
          if (data.upload > 50 && data.upload <= 100) {
            dataUpload[3].value++;
          }
          if (data.upload > 100 && data.upload <= 200) {
            dataUpload[2].value++;
          }
          if (data.upload > 200 && data.upload <= 300) {
            dataUpload[1].value++;
          }
          if (data.upload > 300) {
            dataUpload[0].value++;
          }
        });

        this.dataDownload = dataDownload;
        this.dataUpload = dataUpload;
      });
  }

  lteSpeed(): void {
    this.lteSupported$
      .pipe(
        filter((supported) => supported === true),
        switchMap(() => this.lteService.speedTest$('days', 7))
      )
      .subscribe((response) => {
        this.lteRange = { ...response.statsDateRange, mode: '7d' };
        this.lteBars = [
          ...generateBars(response.downloadSpeeds, 'download', this.lteRange).bars,
          ...generateBars(response.uploadSpeeds, 'upload', this.lteRange).bars
        ];
      });
  }

  startLteSpeedTest(): void {
    this.lteService.startSpeedTest$().subscribe(
      () => {
        this.toast.success(
          'speedtests.speedcontrols.lteSpeedTesStarted',
          'speedtests.speedcontrols.lteSpeedTesStartedTitle'
        );
      },
      (error) => this.toast.error(error.error.message, 'devices.device.error')
    );
  }

  ngOnDestroy(): void {
    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }
  }
}
