import { IHelpPage } from '../help-items';

export const nodes: IHelpPage = {
  helpItems: {
    general: {
      options: {
        nodesNeeded: true
      },
      steps: [
        {
          targetElement: '.tooltip',
          content: 'help.nodes.general.onlineState',
          options: {
            action: {
              type: 'function',
              function: async () => {
                const circle = document.querySelector('#node-connectionCircle .circle');
                if (!circle) return;
                circle.dispatchEvent(new MouseEvent('mouseenter', { bubbles: true }));
                await new Promise((r) => setTimeout(r, 100));
                setTimeout(() => {
                  const width = parseInt(document.querySelector<HTMLDivElement>('.spotlight').style.width, 10);
                  document.querySelector<HTMLDivElement>('.spotlight').style.width = `${width + 20}px`;
                  document.querySelector<HTMLDivElement>('.spotlightShadow').style.width = `${width + 20}px`;
                }, 100);
              }
            },
            exitAction: {
              type: 'function',
              function: async () => {
                document
                  .querySelector('#node-connectionCircle .circle')
                  .dispatchEvent(new MouseEvent('mouseleave', { bubbles: true }));
              }
            }
          }
        },
        {
          targetElement: '#node-hardwareInfo',
          content: 'help.nodes.general.hardwareInfo',
          options: {
            action: {
              type: 'click',
              targetElement: '#node-actionsDropdown [cdkoverlayorigin]'
            },
            exitAction: {
              type: 'click',
              targetElement: '#node-actionsDropdown [cdkoverlayorigin]'
            }
          }
        },
        {
          targetElement: '#node-congestionChart',
          content: 'help.nodes.general.congestion',
          options: {
            expectMovement: true,
            scrollIntoView: true,
            action: {
              type: 'function',
              function: async () => {
                const expanded = document.querySelector<HTMLDivElement>('#node-congestionChart');
                if (!expanded) {
                  document.querySelector<HTMLDivElement>('#node-congestionCollapsibleBox .header').click();
                  await new Promise((r) => setTimeout(r, 100));
                }
              }
            },
            exitAction: {
              type: 'function',
              function: async () => {
                document.querySelector('#customer-view').scrollTop = 0;
                document.querySelector<HTMLDivElement>('#node-congestionCollapsibleBox .header').click();
                await new Promise((r) => setTimeout(r, 100));
              }
            }
          }
        },
        {
          targetElement: '#node-hardwareHealthCharts',
          content: 'help.nodes.general.hardwareHealth',
          options: {
            action: {
              type: 'function',
              function: async () => {
                const expanded = document.querySelector<HTMLDivElement>('#node-hardwareHealthCharts');
                if (!expanded) {
                  document.querySelector<HTMLDivElement>('#node-hardwareHealthCollapsibleBox .header').click();
                  await new Promise((r) => setTimeout(r, 100));
                }
              }
            },
            exitAction: {
              type: 'function',
              function: async () => {
                document.querySelector('#customer-view').scrollTop = 0;
                document.querySelector<HTMLDivElement>('#node-hardwareHealthCollapsibleBox .header').click();
                await new Promise((r) => setTimeout(r, 100));
              }
            }
          }
        }
      ]
    }
  }
};
