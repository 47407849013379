<modal mode="compact" (overlayAction)="closeModal()">
  <ng-container title>{{
    isMlo ? ('qoe.charts.mostRecentMloMeasurements' | translate) : ('qoe.charts.mostRecentMeasurements' | translate)
  }}</ng-container>
  <ng-container content *ngIf="!isMlo">
    <div class="qoeDetails">
      <div class="list" *ngFor="let qoe of device.rawQoe | keyvalue">
        <ng-container *ngIf="qoe.key !== 'mlo'">
          <div class="key">{{ '' + qoe.key | translate }}</div>
          <div class="value">
            {{ qoe.key === 'mac' || qoe.key === 'mldAddr' ? ('' + qoe.value | macAddr) : qoe.value }}
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container content *ngIf="isMlo">
    <div class="qoeHeading">{{ 'qoe.charts.connection' | translate }}</div>
    <div class="qoeDetails">
      <div class="list" *ngIf="device.rawQoe.mldAddr">
        <div class="key">{{ 'mldAddr' | translate }}</div>
        <div class="value">
          {{ device.rawQoe.mldAddr | macAddr }}
        </div>
      </div>
      <div class="list" *ngFor="let qoe of device.rawQoe.mlo | keyvalue">
        <ng-container *ngIf="!isLinkData(qoe.key) && qoe.key !== 'links'">
          <div class="key">{{ '' + qoe.key | translate }}</div>
          <div class="value">
            {{ qoe.key === 'mac' || qoe.key === 'mldAddr' ? ('' + qoe.value | macAddr) : qoe.value }}
          </div>
        </ng-container>
      </div>
    </div>
    <div class="mloLinks">
      <div class="qoeHeading">{{ 'qoe.charts.links' | translate }}</div>
      <div class="sliderBox">
        <slidetoggler [options]="mloRadios" (toggle)="setMLORadio($event)"> </slidetoggler>
      </div>
    </div>
    <div class="qoeDetails">
      <div class="list" *ngFor="let qoe of device.rawQoe.mlo.links[mloRadioIndexSelected] | keyvalue">
        <ng-container *ngIf="isLinkData(qoe.key)">
          <div class="key">{{ '' + qoe.key | translate }}</div>
          <div class="value">
            {{ qoe.key === 'mac' || qoe.key === 'mldAddr' ? ('' + qoe.value | macAddr) : qoe.value }}
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeModal()">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
    <button class="super-primary" (click)="gotoQoe()">
      {{ 'health.networkInformation.modal.qoeData' | translate }}
    </button>
  </ng-container>
</modal>
